import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PlayArrow from '@material-ui/icons/PlayArrow';
import People from '@material-ui/icons/People';
import Label from '@material-ui/icons/Label';

import Info from '@material-ui/icons/Info';

import { PlayerConsumer } from '../contexts/PlayerContext.js';
import { Link } from 'gatsby'

const drawerWidth = 240;

const styles = theme => ({

    root: {
        display: 'inline',
        width: 40,
        [theme.breakpoints.down("sm")]: {
          width: 35
        },
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginRight: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
    
        padding: "5px 10px"
      },
      hide: {
        display: 'none',
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        justifyContent: 'flex-end',
        height:64
      },
      content: {
        flexGrow: 1,
        padding: 10,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      },
      menuLink:{
        textDecoration:"none",
        color:"#233F95"
      }
  
});

class DrawerRight extends React.Component {


  render() {
    const { classes, theme } = this.props;

    return (
        <PlayerConsumer>
          {({ drawerOpen, handleDrawerClose }) => (
            <Drawer
              className={classes.drawer}
              anchor="right"
              open={drawerOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
              <Divider />
              <List>
        
              <Link className={classes.menuLink} to={`/about`} onClick={handleDrawerClose}>
                  <ListItem button >
                    <ListItemIcon> <Info /></ListItemIcon>
                    <ListItemText primary="About" />
                  </ListItem>
              </Link>

 

           
         
              </List>
             
            </Drawer>
          )}
        </PlayerConsumer>
      );

    
  }
}


export default withStyles(styles, { withTheme: true })(DrawerRight);