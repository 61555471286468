import React from "react"
import { StaticQuery, graphql } from "gatsby"
import DrawerRight from './DrawerRight.js'
import { PlayerConsumer } from '../contexts/PlayerContext.js'

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query Layout {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <PlayerConsumer>
      {({  handleDrawerOpen, handleDrawerClose }) => (
      <div>
      
      
        <Header handleDrawerOpen={handleDrawerOpen} />

              <div>
                <main style={{borderTop:"1px solid #ededed", minHeight:"94vh", background:"#f8f8f8"}}>
                  {children}
                  </main>
              </div>
          <DrawerRight />

       


      </div>

      )}

      </PlayerConsumer>
    )}
  />
)

export default Layout