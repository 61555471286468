import React, { Component } from "react"
//import PlayerUsingContext from '../components/PlayerUsingContext.js'

const defaultState = {
  handleDrawerOpen:() => {},
  handleDrawerClose:() => {},
  drawerOpen:false
}

const PlayerContext = React.createContext(defaultState)

export class PlayerProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      drawerOpen:false
    }
  }

  handleDrawerOpen = () => {
    console.log('fired handleDrawerOpen')
    this.setState({
     drawerOpen:true
    })
  };

  handleDrawerClose = () => {
    this.setState({
      drawerOpen:false
     })
  };


  render() {
    const { children } = this.props

   // console.log(this.state)

    return (
      <PlayerContext.Provider
        value={{
      
          handleDrawerOpen:this.handleDrawerOpen,
          handleDrawerClose:this.handleDrawerClose,
          drawerOpen:this.state.drawerOpen


        }}
      >
        {children}
      </PlayerContext.Provider>
    )
  }
}

export const PlayerConsumer = PlayerContext.Consumer
