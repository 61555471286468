import { Link } from "gatsby"
import React from "react"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import Hidden from "@material-ui/core/Hidden"

const styles = {
  root: {
    flexGrow: "1 !important",
  },
  appbar: {
    backgroundColor: "white",
    boxShadow: "none",
  },
  grow: {
    flexGrow: 1,
    color: "#2b3394",
    textDecoration: `none`,
    textTransform: "none",
  },
  link: {
    color: `white`,
    textDecoration: `none`,
    display:"flex",
    alignItems: "baseline",
    justifyContent: "flex-start"
  },
  titleSub:{
    display:"flex",
    alignItems: "baseline",
    justifyContent: "flex-start"


  },
  pageTitle: {
    color: "#2b3394",
    fontSize: "1.6rem",
    marginRight:20
  },
  pageSubTitle:{
    fontSize: "1rem",
    color: "#565874e0",

  },
  menuButton: {
    color: "#2b3394",
  },
  aboutButton:{
    textTransform: 'none'
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes, handleDrawerOpen } = this.props

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <div className={classes.grow}>
            <div className={classes.titleSub}>
              <Link to="/" className={classes.link}>
                <Typography
                  className={classes.pageTitle}
                  variant="title"
                  component="h1"
                >
                  Geojam
                </Typography>
                <Hidden only={['sm', 'xs']}>
        
  
                <Typography
                  className={classes.pageSubTitle}
                  variant="title"
                  component="h3"
                >
                  (a Geospatial Video Collection)
                </Typography>
                </Hidden>
              </Link>
            </div>
            </div>
            <div>
            <Hidden smUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              </Hidden>
              
            <Hidden smDown>
              <Link href="/about">
                <Button color="primary" className={classes.aboutButton}>About</Button>
              </Link>
      
            </Hidden>
            </div>


          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withStyles(styles)(Header)
