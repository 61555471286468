
import React from "react"
import { PlayerProvider } from './src/contexts/PlayerContext.js';
import Layout from './src/components/layout.js';


export const wrapRootElement = ({ element }) => (
  <PlayerProvider>{element}</PlayerProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
